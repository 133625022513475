<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  list-style: none;
  text-decoration: none;
}

html, body {
  font-size: 16px;
  font-family: "SF Pro SC", "SF Pro Text", "SF Pro Icons", PingFang SC, Lantinghei SC, Microsoft Yahei,
  Hiragino Sans GB, Microsoft Sans Serif, WenQuanYi Micro Hei, sans-serif;
  background-color: azure;
}

.wanli-container {
  margin: 0 auto;
  padding: 0 10px;
  width: 100%;
}

@media (min-width: 576px) {
  .wanli-container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .wanli-container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .wanli-container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .wanli-container {
    max-width: 1140px;
  }
}

@media (min-width: 1560px) {
  .wanli-container {
    max-width: 1400px;
  }
}
</style>
