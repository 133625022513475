import {createRouter, createWebHistory} from "vue-router"

const routes = [
    {path: "/", name: "home", redirect: "/articles"},
    {
        path: "/home", component: () => import("@/components/Home"),
        children: [
            {path: "/about", name: "about", component: () => import("@/views/About")},
            {path: "/accounts/self", name: "self", component: () => import("@/views/account/Self")},
            {path: "/accounts/add", name: "accountAdd", component: () => import("@/views/account/AccountAdd")},
            {path: "/accounts/list", name: "accountsList", component: () => import("@/views/account/AccountList")},
            {path: "/accounts/edit", component: () => import("@/views/account/AccountEdit")},
            {path: "/articles", name: "articles", component: () => import("@/views/article/ArticleList")},
            {path: "/articles/detail", name: "articleDetail", component: () => import("@/views/article/ArticleDetail")},
            {path: "/articles/add", component: () => import("@/views/article/ArticleAdd")},
            {path: "/articles/edit", name: "articleEdit", component: () => import("@/views/article/ArticleEdit")},
            {path: "/sign-in", name: "signIn", component: () => import("@/views/account/SignIn")},
            {path: "/sign-up", name: "signUp", component: () => import("@/views/account/SignUp")},
            {path: '/system/logs', component: () => import("@/views/system/SystemLog")},
        ],
    },
    {path: "/accounts/verify", name: "verify", component: () => import("@/views/account/AccountVerify")}


]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
