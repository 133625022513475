import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as Icons from '@element-plus/icons-vue'

// markdown
import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';

import ECharts from 'vue-echarts'
import {use} from "echarts/core";

import {CanvasRenderer} from 'echarts/renderers'
import {BarChart} from 'echarts/charts'
import {GridComponent, TooltipComponent} from 'echarts/components'

// highlightjs
import hljs from 'highlight.js';

use([
    CanvasRenderer,
    BarChart,
    GridComponent,
    TooltipComponent
]);

VueMarkdownEditor.use(githubTheme, {
    Hljs: hljs
});

const app = createApp(App);
app.use(router).use(ElementPlus).use(VueMarkdownEditor);
app.component('v-chart', ECharts)
app.mount('#app')

// 注册Icons 全局组件
Object.keys(Icons).forEach(key => {
    app.component(key, Icons[key])
})

